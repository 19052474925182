import actions from '@/services/store/modules/wheel/actions';
import getters from '@/services/store/modules/wheel/getters';
import mutations from '@/services/store/modules/wheel/mutations';
import state from '@/services/store/modules/wheel/state';

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
