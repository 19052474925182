// * Global
import Vue from 'vue';
import Vuex from 'vuex';
// * Modules
import WheelModule from './modules/wheel';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        wheel: WheelModule,
    },
})
