export default {
    login(state, payload) {
        state.isAuth = !!payload
    },
    permission(state, payload) {
        state.permission = payload
    },
    fillUser(state, payload) {
        state.user = payload
    }
}
