import Vue from "vue";

Vue.filter('twoDigits', (value) => {
    if (value < 0) {
        return '00';
    }
    if (value.toString().length <= 1) {
        return `0${value}`;
    }
    return value;
});

Vue.filter('toPlural', (value, one, two, five, isShowValue = true) => {
    let n = Math.abs(value);
    n %= 100;

    const quantity = isShowValue ? value : '';

    if (n >= 5 && n <= 20) {
        return `${five}`;
    }

    n %= 10;
    if (n === 1) {
        return `${one}`;
    }

    if (n >= 2 && n <= 4) {
        return `${two}`;
    }
    if(!value) {
        return ''
    }

    return `${five}`;
});
