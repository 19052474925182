<template>
  <div class="container is-text-center">
    <h1>404 Not Found</h1>
  </div>
</template>

<script>
export default {
  name: 'TheNotFoundPage',
};
</script>
<style scoped lang="scss">
.is-text-center {
	text-align: center;
}
</style>
