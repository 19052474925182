<template>
  <div id="app">
	<component :is="layout"/>
  </div>
</template>

<script>

import MainLayout from '@/layouts/MainLayout.vue';
import SystemLayout from '@/layouts/SystemLayout.vue';

const DEFAULT_LAYOUT = 'main';

export default {
	name: 'App',
	components: {
		MainLayout,
		SystemLayout
	},
	
	data() {
		return {
			layouts: {
				main: MainLayout,
				system: SystemLayout,
			},
		};
	},
	computed: {
		layout() {
			return `${this.$route.meta.layout || DEFAULT_LAYOUT}-layout`;
		},
	},
}
</script>

