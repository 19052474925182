import axios from "axios";

const url = process.env.VUE_APP_API_URL || 'https://api.wheel.rabotut.ru'

export default {
    login({commit}, payload) {
        return new Promise((resolve, reject) => {
            const formData = new FormData()
            formData.append('phone', payload);
            axios({
                method: 'post',
                url: `${url}/login`,
                data: {
                    name: payload.name,
                    email: payload.email
                }
            })
                .then((res) => {
                    if (res.status && res.status === 200) {
                        localStorage.setItem("token", res.data.token);
                        commit('login', res.data.token);
                    }
                    resolve(res)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error)
                });


        })
    },
    onSignIn({commit}, payload) {
        return new Promise((resolve, reject) => {
            localStorage.setItem("token", payload.accessToken || '');
            commit('login', payload.accessToken || '');
        })
    },
    logout({commit}) {
        commit('login', "");
        commit('permission', true);
        localStorage.removeItem('token')
    },
    changePermission({commit}, payload) {
        commit('permission', payload)
    },
    loadUser({commit}) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('token');
            axios({
                method: 'get',
                url: `${url}/me`,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            })
                .then((res) => {
                    if (res && res.status === 200) {
                        const data = {
                            tries: res.data.tries,
                            email: res.data.email,
                            name: res.data.name || '',
                            id: res.data.id,
                            phone: res.data.phone || '',
                            address: {
                                apartment: res.data.apartment || '',
                                city: res.data.city || '',
                                house: res.data.house || '',
                                index: res.data.index || '',
                                street: res.data.street || '',
                            },
                            prizes: res.data.prizes || []
                        }
                        commit('fillUser', data)
                    }
                    resolve(res)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error)
                });
        })
    },
    onStartWheel() {
        const token = localStorage.getItem('token');
        if (token) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: `${url}/roll`,
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err);
                        reject(err)
                    })
            })
        } else {
            throw new Error('Токен не найден')
        }
    },
    sendAddress(_, payload) {
        const token = localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: `${url}/address`,
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            })
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    resetPrizes() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('token');
            axios({
                method: 'get',
                url: `${url}/reset`,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            })
                .then((res) => {
                    console.log('resetPrizes Ok')
                    resolve(res)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error)
                });
        })
    },
}
