<template>
	<div id="layout" class="layout layout--system">
		<main class="main-content">
			<router-view />
		</main>
	</div>
</template>

<script>
export default {
	name: "SystemLayout"
}
</script>

<style lang="scss" scoped>
.layout--system {
	display: flex;
	flex-direction: column;
	flex: 1 0;
	min-height: 100vh;
	justify-content: space-between;
	font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Arial, sans-serif;
	
	.main-content {
		display: flex;
		flex-direction: column;
		flex: 1 0;
	}
}
</style>
