import Vue from 'vue';
import VueRouter from 'vue-router';
import TheNotFoundPage from '@/pages/system/TheNotFoundPage.vue';

Vue.use(VueRouter);

const checkAuth = (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem('token')
    if(!token) {
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'main',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '@/pages/HomePage'),
  },
  {
    path: '/giveaway',
    name: 'giveaway',
    meta: {
      layout: 'main',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '@/pages/GiveawayPage'),
    beforeEnter: checkAuth,
  },
  {
    path: '/end',
    name: 'end',
    meta: {
      layout: 'main',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '@/pages/EndPage'),
    beforeEnter: checkAuth,
  },
  {
    path: '/authorizer',
    name: 'authorizer',
    meta: {
      layout: 'main',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '@/pages/auth/Authorizer'),
  },
  {
    path: '/403',
    name: '403',
    meta: {
      layout: 'main',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '@/pages/system/403'),
  },
  {
    path: '*',
    name: '404',
    meta: {
      layout: 'system',
      requiresAuth: false,
    },
    component: TheNotFoundPage,
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
