<template>
	<div id="layout" class="layout layout--main">
		<main class="main-content ">

			<router-view />
		</main>
	</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
	name: "MainLayout",
	methods:{
		...mapActions('wheel', ['logout']),
		onLogout() {
			this.logout()
			this.$router.push("/login")
		}
	},
	mounted() {
		const now = Math.trunc((new Date()).getTime() / 1000)
		const timerValue = process.env.VUE_APP_TIME_VALUE || '2024-01-30T23:59:59.999Z'
		const dateInMilliseconds = Math.trunc(Date.parse(timerValue) / 1000)
		if(dateInMilliseconds < now) {
			this.$router.push('/end')
		}
	}
	
}
</script>

<style lang="scss" scoped>
.layout--main {
	display: flex;
	flex-direction: column;
	flex: 1 0;
	min-height: 100vh;
	justify-content: space-between;
	//background-image: url("../assets/bg-small.svg");
	background-size: cover;
	background-repeat: no-repeat;
	@media (min-width: 680px) {
		//background-image: url("../assets/bg-medium.svg");
	}
	@media (min-width: 1025px) {
		//background-image: url("../assets/bg-large.svg");
	}
	
	.main-content {
		height: 100vh;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		position: relative;
		.logout {
			display: flex;
			justify-content: flex-end;
			.logout-button {
				height: 40px;
				padding: 10px 25px;
				border: 1px solid rgba(34,36,38,.15);
				font-weight: 500;
				background: transparent;
				cursor: pointer;
				border-radius: .28571429rem;
				transition: all 0.3s ease;
				position: relative;
				display: inline-block;
			}
			.logout-button:hover {
				background-color: #004185;
				color: white;
			}
		}
	}
}
</style>
