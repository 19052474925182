import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/services/store'
import '@/assets/styles/theme.scss';
import '@/common/filters';
import VueMask from 'v-mask'

Vue.use(VueMask);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
